(function(){
     'use strict';

     angular
        .module('app')
        .factory('GlobalvarsService', GlobalvarsService);

    GlobalvarsService.$inject = [];

    function GlobalvarsService(){

		this.access_token = "";
		this.puntos = 0;
	    this.notificaciones = 0;
	    this.user = {};
	    this.provincias = [];
	    this.regalos = 0;
	    this.pedido = { "id":"", "carrito":[] };
	    this.direcciones = [];
		 
		this.setPuntos = function(puntos){
			this.puntos = puntos;
		}

		this.setUser = function(user){
		  this.user = user;
		}
		this.getUser = function(){
		  return this.user;
		}

		this.getProvincias = function(){
		  return this.provincias;
		}
		this.setProvincias = function(provincias){
		  this.provincias = provincias;
		}
		

		this.setAccessToken = function(access){
		  this.access_token = access;
		}
		this.getAccessToken = function(){
		  return this.access_token;
		}

		this.setNotificaciones = function(notificaciones){
		this.notificaciones = notificaciones;
		}
		this.getNotificaciones = function(){
		return this.notificaciones;
		}

		this.setRegalos = function(regalos){
		this.regalos = regalos;
		}
		this.getRegalos = function(){
		  return this.regalos;
		}

		this.getPuntos = function(){
			return this.puntos;
		}

		this.getNumCarrito = function(){
		  var sum = 0;
		  for(var cont=0; cont<this.pedido.carrito.length;cont++){
		    sum+=parseInt(this.pedido.carrito[cont].cantidad);
		  }
		  return sum;
		}

		this.addCarrito = function(regalo,cantidad){
		  regalo.cantidad = cantidad;
		  this.pedido.carrito.push(regalo);
		}

		this.buscarRegaloCarrito = function(regalo){
		  var res = false;
		  for(var cont=0;cont<this.pedido.carrito.length;cont++){
		    if(this.pedido.carrito[cont].id==regalo.id){
		      res = true;
		    }
		  }
		  return res;
		}
		this.modificarSumCantidad = function(regalo,cantidadASumar){
		  for(var cont=0;cont<this.pedido.carrito.length;cont++){
		    if(this.pedido.carrito[cont].id==regalo.id){
		      console.log(this.pedido.carrito[cont]);
		      console.log(cantidadASumar);
		      this.pedido.carrito[cont].cantidad = parseInt(this.pedido.carrito[cont].cantidad) + parseInt(cantidadASumar);
		      console.log(this.pedido.carrito[cont].cantidad);
		    }
		  }
		}
		

		this.getCarrito = function(){
		  return this.pedido.carrito;
		}

		this.getSumPuntosCarrito = function(){
		  var sum = 0;
		  for(var cont=0; cont<this.pedido.carrito.length;cont++){
		    sum+=parseInt(this.pedido.carrito[cont].puntos)*parseInt(this.pedido.carrito[cont].cantidad);
		  }
		  return sum;
		}

		this.borrarCarrito = function(carrito){
		  var buscar = false;
		  for(var cont=0;cont<this.pedido.carrito.length && !buscar;cont++){
		    if(this.pedido.carrito[cont]==carrito){
		      this.pedido.carrito.splice(cont, 1);
		      buscar = true;
		    }
		  }
		}

		this.cleanCarrito = function(){
		  this.pedido.carrito = [];
		} 
		return this;

    }
})();