(function() {
    'use strict';

    angular
    .module('app',['ngSanitize','ngRoute','ngStorage'])
    .config(function($routeProvider) {
        $routeProvider
        .when("/", {
            templateUrl : "/js/app/views/index.html",
            controller: 'ContentController',
            controllerAs : 'indexCtrl',
            resolve: {
                /*user: function(UserService) {
                    var user = {};
                    user.username = $localStorage.email;
                    user.password = $localStorage.pass;
                    console.log(UserService.auth(user));
                    return UserService.auth(user);
                    // Using data resolved in parent state.
                    //return UserService.auth(user.accountId);
                }*/
                user: ['UserService','$localStorage', function(UserService,$localStorage) {
                    var user = {};
                    user.username = $localStorage.email;
                    user.password = $localStorage.pass;
                    return UserService.auth(user,function(data){
                        console.log(data);
                        return data.data.user;
                    });
                }]
            }
        })
        .when("/notificaciones", {
            templateUrl : "/js/app/views/notificaciones.html",
            controller: 'NotificacionesController'
        })
        .when("/sumar-puntos", {
            templateUrl : "/js/app/views/sumarpuntos.html",
            controller: 'SumarpuntosController'
        }).when("/regalos", {
            templateUrl : "/js/app/views/regalos.html",
            controller: 'RegalosController'
        }).when("/productos", {
            templateUrl : "/js/app/views/productos.html",
            controller: 'ProductosController'
        }).when("/mi-perfil", {
            templateUrl : "/js/app/views/miperfil.html",
            controller: 'MiperfilController'
        }).when("/resumen-puntos", {
            templateUrl : "/js/app/views/resumenpuntos.html",
            controller: 'ResumenpuntosController'
        }).when("/historial-regalos", {
            templateUrl : "/js/app/views/historialregalos.html",
            controller: 'HistorialregalosController'
        }).when("/contacto", {
            templateUrl : "/js/app/views/contacto.html",
            controller: 'ContactoController'
        }).when("/login", {
            templateUrl : "/js/app/views/login.html",
            controller: 'LoginController',
            controllerAs : 'loginCtrl'
        });
    });
})();
