(function(){
    'use strict';

    angular
    .module('app')
    .controller('MiperfilController', MiperfilController)
 
    MiperfilController.$inject = ["$http","$timeout", "$log", "$document","$sce"];
              
    function MiperfilController($http,$timeout, $log, $document,$sce)
    {                         
        var vm = this; 
    } 
  
})();