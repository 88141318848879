(function(){
     'use strict';

     angular
        .module('app')
        .factory('UserService', UserService);

    UserService.$inject = ['GlobalvarsService','$http'];

    function UserService(GlobalvarsService,$http){


        var service= {
           auth:auth,
           getUser:getUser
        };

        return service;
 		function auth(vm,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            var data = $.param({
               grant_type:'password',
               client_id : '99800b85d3383e3a2fb45eb7d0066a4879a9dad0',
				client_secret:'99800b85d3383e3a2fb45eb7d0066a4879a9dad0',
				password : vm.password,
				username : vm.username,
				email : vm.username
            });
            $http.post('/auth/token', data, config).then(function (success){
            	callback(success);
			},function (error){
            	callback({status:500,error:error});
			});
        }

        function getUser(vm,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                },
                params: {
                   access_token : GlobalvarsService.getAccessToken()
                }
            }

           
            $http.get('/ajax/getuser', config).then(function (success){
                callback(success);
            },function (error){
                callback({status:500,error:error});
            });
        }

        /*
        getUser(): Observable<any> {
     let params: URLSearchParams = new URLSearchParams();
      params.set('access_token', this.globalVars.getAccessToken());
      return this.http.get(this.globalVars.url+'ajax/getuser',{
          search: params
      }).map(res => res.json());
     
  }

  tokenpush(token): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
      params.set('access_token', this.globalVars.getAccessToken());
      params.set('push',token);
    return this.http.get(this.globalVars.url+'ajax/editarpush',{
        search: params
    }).map(res => res.json());
     
  }

  getProvincias(): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.globalVars.url+'registro/getprovincias',{
        search: params
    }).map(res => res.json());
     
  }

  auth(email,pass): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
     params.set('grant_type', "password");
     params.set('client_id', "99800b85d3383e3a2fb45eb7d0066a4879a9dad0");
     params.set('client_secret', "99800b85d3383e3a2fb45eb7d0066a4879a9dad0");
     params.set('password', pass);
     params.set('username', email);
     params.set('email', email);
      return this.http.get(this.globalVars.url+'auth/token',{
        search: params
    }).map(res => res.json());
  }

  registro(form): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
     params.set('form', JSON.stringify(form));
      return this.http.get(this.globalVars.url+'registro/registro',{
        search: params
    }).map(res => res.json());
  }
  recuperar(email): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
     params.set('email', email);
      return this.http.get(this.globalVars.url+'registro/recuperar',{
        search: params
    }).map(res => res.json());
  }
  

  contacto(contacto): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
     params.set('access_token', this.globalVars.getAccessToken());
     params.set('contacto', JSON.stringify(contacto));
      return this.http.get(this.globalVars.url+'ajax/contacto',{
        search: params
    }).map(res => res.json());
  }

  editarPerfil(form): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
     params.set('form', JSON.stringify(form));
      params.set('access_token', this.globalVars.getAccessToken());
      return this.http.get(this.globalVars.url+'ajax/editarperfil',{
        search: params
    }).map(res => res.json());
  }
        */

    }
})();