(function(){
    'use strict';
    angular
    .module('app')
    .controller('AppController', AppController)
 
    AppController.$inject = ['UserService','GlobalvarsService',"$http","$timeout", "$log", "$document","$sce","$localStorage"];
   
    function AppController(UserService,GlobalvarsService,$http,$timeout, $log, $document,$sce,$localStorage)
    {                        
        var vm = this; 
        vm.user = {};
        vm.puntos = 0;
        if($localStorage.email!="" && $localStorage.pass!=""){
        	vm.user.username = $localStorage.email;
        	vm.user.password = $localStorage.pass;
        	UserService.auth(vm.user,function(data){
        		if(typeof data.access_token!=undefined && data.status==200){
        			GlobalvarsService.setAccessToken(data.data.access_token);
        			//console.log(data.data.access_token);
        			UserService.getUser("",function(data){
			          GlobalvarsService.setPuntos(data.data.puntos);
			          GlobalvarsService.setRegalos(data.data.regalos);
			          GlobalvarsService.setProvincias(data.data.provincias);
			          GlobalvarsService.setNotificaciones(data.data.notificaciones);
			          GlobalvarsService.setUser(data.data.user);
			          $timeout(function(){
			          	vm.user = GlobalvarsService.getUser();
			          	vm.puntos = GlobalvarsService.getPuntos();
        			  	window.location = "/#!/";
			          })
			        });

        		}
        		if(data.status==500){
        			$localStorage.email = "";
					$localStorage.pass = "";
        		}
        	});
        }
        /*
			this.storage.get('email').then((email) => {
		        if(email!=null){
		          this.storage.get('pass').then((pass) => {
		            if(pass!=null){
		              this.userprov.auth(email,pass).subscribe(
		                data => {
		                  if(typeof data.access_token!=undefined){
		                    //Logeando correctamente y poniendo el token push
		                    this.globalVars.setAccessToken(data.access_token);
		                    if((<any>window).cordova){
		                      this.push.register().then((t: PushToken) => {
		                        return this.push.saveToken(t);
		                      }).then((t: PushToken) => {
		                        this.userprov.tokenpush(t.token).subscribe(
		                        data => {
		                        }, 
		                        error =>  { 
		                        });
		                      });
		                    }
		                    this.rootPage = HomePage;
		                    //this.navCtrl.setRoot(HomePage, {}, {animate: true, direction: 'forward',animation:'ios-transition'});
		                  }else{
		                    this.storage.set('email', null);
		                    this.storage.set('pass', null);
		                    splashScreen.hide();
		                  }
		                  
		                },
		              error =>  { 
		                this.errorMessage = <any>error 
		                splashScreen.hide();
		              });
		            }
		          });
        */
    }

})();