(function(){
    'use strict';

    angular
    .module('app')
    .controller('ContentController', ContentController)
 
    ContentController.$inject = ['user','UserService','GlobalvarsService',"$http","$timeout", "$log", "$document","$sce"];
              
    function ContentController(user,UserService,GlobalvarsService,$http,$timeout, $log, $document,$sce)
    {                         
        var vm = this; 
        console.log(user);
        //vm.user = GlobalvarsService.getUser();
        //vm.puntos = GlobalvarsService.getPuntos();
        //console.log(vm.user);
	    //console.log(GlobalvarsService.getAccessToken());
	    /*this.user.getUser().subscribe(
        data => {
          this.globalVars.setPuntos(data.puntos);
          this.globalVars.setRegalos(data.regalos);
          this.globalVars.setProvincias(data.provincias);
          this.globalVars.setNotificaciones(data.notificaciones);
          this.globalVars.setUser(data.user);
          //this.puntos = this.globalVars.getPuntos(); 
          callback();
          
        },
      error =>  this.errorMessage = <any>error );*/
    } 
  
})();