/*(function() {
    'use strict';

    angular.module("app", ["ngRoute"])
    .config(function($routeProvider) {
        $routeProvider
        .when("/", {
            templateUrl : "/js/app/views/main.html",
            controller: 'ContentController',
            controllerAs: 'cnt'
        });
    });

})();


*/