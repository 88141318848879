(function(){
    'use strict';

    angular
    .module('app')
    .controller('LoginController', LoginController)
 
    LoginController.$inject = ['UserService',"$http","$timeout", "$log", "$document","$sce",'$localStorage'];
              
    function LoginController(UserService,$http,$timeout, $log, $document,$sce, $localStorage)
    {                    
    console.log($localStorage);     
        var vm = this;
        vm.msgerror = false; 
        vm.user = {};
        vm.user.username = "";
        vm.user.password = "";
        vm.user.recordar = true;
        vm.logear = function(){
        	UserService.auth(vm.user,function(data){
	            if(data.status==200){
	        		if(typeof data.access_token!=undefined){
                		$localStorage.email = vm.user.username;
                		$localStorage.pass = vm.user.password;
		            }else{
		            	$localStorage.email = "";
		                $localStorage.pass = "";
		            }
		        }
		        if(data.status==500){
		        	$timeout(function(){
		        		vm.msgerror = true;
		        	})
		        }
            });
		    /*this.userprov.auth(this.email,this.pass).subscribe(
		      data => {
		        loading.dismiss();
		        if(typeof data.access_token!=undefined){
		          this.globalVars.setAccessToken(data.access_token);
		          this.push.register().then((t: PushToken) => {
		            return this.push.saveToken(t);
		          }).then((t: PushToken) => {
		            this.userprov.tokenpush(t.token).subscribe(
		            data => {
		            }, 
		            error =>  { 
		            });
		          });
		          this.storage.set('email', this.email);
		          this.storage.set('pass', this.pass);
		          this.navCtrl.setRoot(HomePage, {}, {animate: true, direction: 'forward',animation:'ios-transition'});

		        }else{
		          this.storage.set('email', null);
		          this.storage.set('pass', null);
		        }
		      },
		    error => { 
		      this.errorMessage = <any>error 
		      this.storage.set('email', null);
		      this.storage.set('pass', null);
		      let self = this;
		      setTimeout(function(){
		        loading.dismiss();
		        const toast = self.toastCtrl.create({
		          message: 'El usuario y contraseña no coinciden, asegúrese y vuelva a intentarlo de nuevo.',
		          showCloseButton: true,
		          closeButtonText: 'Ok',
		          duration: 4000
		        });
		        toast.present();
		        },1000)
		      
		    });*/
		}
    } 
  
})();